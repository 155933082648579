import { L10n } from './types';

const en: L10n = {
  animalAttackChooseWeapon: 'Choose weapon.',
  animalAttackTitle: '{animalName} attack!',
  animalDeerElkName: 'Elk',
  animalWolfArcticName: 'Arctic wolf',
  containerActionPickUp: 'Pick up',
  containerActionSearch: 'Search',
  containerEmpty: 'No items',
  containerFooterInventoryCapacity: 'Inventory capacity {items}/{capacity}',
  containerTitle: 'Container: {containerType}',
  containerTypeBackpack: 'Backpack',
  containerTypeSack: 'Sack',
  containerTypeTrunk: 'Trunk',
  controlInventoryShortcut: 'I',
  controlOutfitShortcut: 'O',
  controlPauseMenuShortcut: 'Esc',
  controlRadialMenuShortcut: '⎵',
  fireActionStart: 'Start fire',
  fireFuel: 'Fuel',
  fireStarter: 'Starter',
  fireTinder: 'Tinder',
  gameEndedMessage: 'Game over',
  gameIntroductionAction: 'Shit!',
  gameIntroductionLoading: 'Loading',
  gameIntroductionQuote: 'What the hell happened? Where am I?',
  interactableActionHarvest: 'Harvest',
  interactableActionPickUp: 'Pick up',
  interactableBushBearberryName: 'Bearberry bush',
  interactableCarcassDeerElkName: 'Elk carcass',
  interactableFuelStickName: 'Stick',
  interactableStarterMatchesName: 'Matches',
  interactableTinderNewspaperName: 'Newspaper',
  inventoryItemActionDrink: 'Drink',
  inventoryItemActionEat: 'Eat',
  inventoryMessageNoItems: 'No items in inventory.',
  inventoryMessageNoItemSelected: 'No item selected.',
  inventoryTitle: 'Inventory',
  itemClothingHatBeanieWoolDescription: 'A beanie made from wool. Warm and waterproof.',
  itemClothingHatBeanieWoolName: 'Wool beanie',
  itemClothingJacketDenimDescription:
    'The top half of the canadian tuxedo. Windproof, but not very warm.',
  itemClothingJacketDenimName: 'Denim jacket',
  itemClothingPantsJeansDescription:
    'The bottom half of the canadian tuxedo. Built for style, but not for the cold.',
  itemClothingPantsJeansName: 'Jeans',
  itemClothingShirtCottonDescription: 'A light cotton shirt. Not warm at the slightest.',
  itemClothingShirtCottonName: 'Cotton shirt',
  itemClothingShoesSneakersDescription: 'Basic sneakers. Built for running, not snow.',
  itemClothingShoesSneakersName: 'Sneakers',
  itemClothingSocksCottonDescription: 'Thin socks made from cotton. Decent, but not great.',
  itemClothingSocksCottonName: 'Cotton socks',
  itemClothingSocksWoolDescription: 'Socks made from wool. Soft and warm.',
  itemClothingSocksWoolName: 'Wool socks',
  itemClothingUnderwearCottonDescription: 'Cotton underwear. Stylish and thin.',
  itemClothingUnderwearCottonName: 'Cotton underwear',
  itemConsumableBearberryDescription: 'A wild, edible berry.',
  itemConsumableBearberryName: 'Bearberry',
  itemConsumableBottleWaterDescription: 'Ice cold and refreshing.',
  itemConsumableBottleWaterName: 'Bottle of water',
  itemConsumableCrispsTomatoDescription: 'A classic canadian snack. Tasty but not very filling.',
  itemConsumableCrispsTomatoName: 'Tomato crisps',
  itemConsumableMeatDeerCookedDescription: 'Cooked deer meat. Delicious and filling.',
  itemConsumableMeatDeerCookedName: 'Deer meat',
  itemConsumableMeatDeerUncookedDescription:
    'Uncooked deer meat. Stinky and not edible until cooked.',
  itemConsumableMeatDeerUncookedName: 'Deer meat (uncooked)',
  itemFireFuelStickDescription: "A stick. Good for fuel, but won't last long",
  itemFireFuelStickName: 'Stick',
  itemFireStarterMatchesDescription: 'A pack of matches, very useful for starting fires.',
  itemFireStarterMatchesName: 'Matches',
  itemFireTinderNewspaperDescription:
    'A newspaper. More useful as tinder than source of information.',
  itemFireTinderNewspaperName: 'Newspaper',
  itemMaterialGutDescription: 'Gut. Gained from harvesting a carcass. Used for crafting.',
  itemMaterialGutName: 'Gut',
  itemMaterialHideDeerDescription: 'Deer hide. Useful for crafting.',
  itemMaterialHideDeerName: 'Deer hide',
  itemWeaponFistsBareDescription: 'Low damage, but if that is all you have ...',
  itemWeaponFistsBareName: 'Bare fists',
  itemWeaponKnifeHuntingDescription: 'Great for hunting and preparing game.',
  itemWeaponKnifeHuntingName: 'Hunting knife',
  outfitItemTypeHat: 'Hat',
  outfitItemTypeJacket: 'Jacket',
  outfitItemTypePants: 'Pants',
  outfitItemTypeShirt: 'Shirt',
  outfitItemTypeShoes: 'Shoes',
  outfitItemTypeSocks: 'Socks',
  outfitItemTypeUnderwear: 'Underwear',
  outfitStatisticProtection: 'Protection',
  outfitStatisticWarmth: 'Warmth',
  outfitTitle: 'Outfit',
  radialMenuActionFire: 'Make fire',
  radialMenuTitle: 'Radial',
  weatherConditions: 'Conditions',
  weatherConditionsOvercast: 'Overcast',
  weatherConditionsSnowing: 'Snowing',
  weatherConditionsSunny: 'Sunny',
  weatherTemperature: 'Temperature',
};

export default en;
