import { Fire } from './types';

const fireBasic: Fire = {
  duration: 10,
  heat: 10,
  id: '00000000-0000-0000-0000-000000000000',
  position: { left: 0, top: 0 },
};

export default fireBasic;
